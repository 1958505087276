/* eslint-disable comma-dangle */
import { lazy } from "react";

import Login from "../components/Login";
import NotFound from "../components/NotFound";
import { PageTemplate } from "../types/page-template";
// import HAOpportunityListing from "../components/HAOpportunityListing";

const MyProfile = lazy(() =>
  import(
    "../components/Profile/MyProfile"
    /* webpackPrefetch: true */
    /* webpackChunkName: "my-profile" */
  ),
);
const Dashboard = lazy(() =>
  import(
    "../components/Dashboard"
    /* webpackPrefetch: true */
    /* webpackChunkName: "dashboard" */
  ),
);

// const CustomerListing = lazy(() =>
//   import(
//     "../components/CustomerListing"
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "customer-listing" */
//   ),
// );
const CustomerDetails = lazy(() =>
  import(
    "../components/CustomerDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "customer-edit" */
  ),
);

// const StaffManagement = lazy(() =>
//   import(
//     "../components/StaffManagement"
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "staff-management" */
//   ),
// );
const StaffProfile = lazy(() =>
  import(
    "../components/Profile/StaffProfile"
    /* webpackPrefetch: true */
    /* webpackChunkName: "staff-profile" */
  ),
);

const EnquiryListing = lazy(() =>
  import(
    "../components/EnquiryListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "enquiries-listing" */
  ),
);
const EnquiryDetails = lazy(() =>
  import(
    "../components/EnquiryDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "enquiries-details" */
  ),
);

// const RentalMasters = lazy(() =>
//   import(
//     "../components/RentalMasters"
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "rental-masters" */
//   ),
// );

const RentalPropertyListing = lazy(() =>
  import(
    "../components/RentalPropertyListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-property-listing" */
  ),
);
const RentalPropertyDetails = lazy(() =>
  import(
    "../components/RentalPropertyDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-property-details" */
  ),
);
const RentalTripListing = lazy(() =>
  import(
    "../components/RentalTripListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-trip-listing" */
  ),
);
const RentalTripDetails = lazy(() =>
  import(
    "../components/RentalTripDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-trip-details" */
  ),
);
const RentalCalendar = lazy(() =>
  import(
    "../components/RentalCalendar"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-calendar" */
  ),
);

const AgentsListing = lazy(() =>
  import(
    "../components/AgentListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "agents-listing" */
  ),
);
const AgentDetails = lazy(() =>
  import(
    "../components/AgentDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "agent-details" */
  ),
);

const DevelopmentOpportunityListing = lazy(() =>
  import(
    "../components/DevelopmentOpportunityListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "development-opportunity-listing" */
  ),
);

const DevelopmentOpportunityDetails = lazy(() =>
  import(
    "../components/DevelopmentOpportunityDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "development-opportunity-details" */
  ),
);

const ChapterListing = lazy(() =>
  import(
    "../components/ChapterListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "chapter-listing" */
  ),
);

const ChapterDetails = lazy(() =>
  import(
    "../components/ChapterDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "chapter-details" */
  ),
);

const ReservationListing = lazy(() =>
  import(
    "../components/RentalReservationListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "reservation-listing" */
  ),
);

const HAOpportunityListing = lazy(() =>
  import(
    "../components/HAOpportunityListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "home-acquisition-listing" */
  ),
);

const HAOpportunityDetails = lazy(() =>
  import(
    "../components/HAOpportunityDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "home-acquisition-listing" */
  ),
);

const ConciergeTicketListing = lazy(() =>
  import(
    "../components/ConciergeTicketListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "concierge-ticket-listing" */
  ),
);

const ConciergeDetails = lazy(() =>
  import(
    "../components/ConciergeTicketDetails/ConciergeTicketDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "concierge-details" */
  ),
);

// const OutboundOpportunityListing = lazy(() =>
//   import(
//     "../components/OutboundOpportunityListing"
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "outbound-opportunity-listing" */
//   ),
// );

const OutboundOpportunityDetails = lazy(() =>
  import(
    "../components/OutboundOpportunityDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "outbound-opportunity-listing" */
  ),
);

const RentalTripApprovalListing = lazy(() =>
  import(
    "../components/RentalTripApprovalListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-trip-approval-listing" */
  ),
);

const RentalCommissionApprovalListing = lazy(() =>
  import(
    "../components/RentalCommissionApprovalListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-commission-approval-listing" */
  ),
);

const RentalPaymentApprovalListing = lazy(() =>
  import(
    "../components/RentalPaymentApprovalListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-payment-approval-listing" */
  ),
);

// const RentalDepositApprovalListing = lazy(() =>
//   import(
//     "../components/RentalDepositApprovalListing"
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "rental-deposit-approval-listing" */
//   ),
// );

const RentalInvoiceApprovalListing = lazy(() =>
  import(
    "../components/RentalInvoiceApprovalListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-invoice-approval-listing" */
  ),
);

const RentalForceBookingApprovalListing = lazy(() =>
  import(
    "../components/RentalForceBookingApprovalListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-trip-approval-listing" */
  ),
);

const RequestTicketListing = lazy(() =>
  import(
    "../components/RequestTicketListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "rental-invoice-approval-listing" */
  ),
);

const DeliveryTracker = lazy(() =>
  import(
    "../components/DeliveryTracker"
    /* webpackPrefetch: true */
    /* webpackChunkName: "agents-listing" */
  ),
);

const ChapterDeliveryTracker = lazy(() =>
  import(
    "../components/ChapterDeliveryTracker"
    /* webpackPrefetch: true */
    /* webpackChunkName: "agents-listing" */
  ),
);

const SiteViewTrackerListing = lazy(() =>
  import(
    "../components/SiteViewTrackerListing"
    /* webpackPrefetch: true */
    /* webpackChunkName: "agents-listing" */
  ),
);

const PAGE_TEMPLATES: PageTemplate[] = [
  // LOGIN
  {
    path: "/login",
    Component: Login,
    secure: false,
  },
  // PROFILE
  {
    path: "/profile",
    Component: MyProfile,
    secure: true,
    redirect: "/login",
  },
  // DASHBOARD
  {
    name: "My Tasks",
    path: "/",
    Component: Dashboard,
    secure: true,
    redirect: "/login",
    acl: "DASHBOARD_TASK_VIEW",
  },
  {
    path: "/enquiries/:enquiry_id",
    Component: EnquiryDetails,
    secure: true,
    redirect: "/login",
    acl: "DASHBOARD_ENQUIRIES_VIEW",
  },
  // ENQUIRIES
  {
    name: "Enquiries",
    path: "/enquiries",
    Component: EnquiryListing,
    secure: true,
    redirect: "/login",
    acl: "ENQUIRY_LIST_VIEW",
  },
  {
    path: "/enquiries/:enquiry_id",
    Component: EnquiryDetails,
    secure: true,
    redirect: "/login",
    acl: "ENQUIRY_VIEW",
  },
  // RENTALS
  {
    name: "Rental",
    pages: [
      {
        name: "Trips",
        path: "/rentals/trips",
        Component: RentalTripListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_LIST_VIEW",
      },
      {
        path: "/rentals/trips/:tid",
        Component: RentalTripDetails,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_VIEW",
      },
      {
        name: "Properties",
        path: "/rentals/property",
        Component: RentalPropertyListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_PROPERTY_LIST_VIEW",
      },
      {
        path: "/rentals/property/:pid",
        Component: RentalPropertyDetails,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_PROPERTY_VIEW",
      },
      // {
      //   name: "Masters",
      //   path: "/rentals/masters",
      //   Component: RentalMasters,
      //   secure: true,
      //   redirect: "/login",
      //   acl: "RENTAL_MASTERS_VIEW",
      // },
      {
        name: "Calendar",
        path: "/rentals/calendar",
        Component: RentalCalendar,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_CALENDAR_VIEW",
      },
      {
        name: "Reservations",
        path: "/rentals/reservations",
        Component: ReservationListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_RESERVATION_LIST_VIEW",
      },
      // {
      //   name: "Outbound",
      //   path: "/outbound/opportunities",
      //   Component: OutboundOpportunityListing,
      //   secure: true,
      //   redirect: "/login",
      //   acl: "DEVELOPMENT_OPP_VIEW",
      // },
      {
        path: "/outbound/opportunities/:oid",
        Component: OutboundOpportunityDetails,
        secure: true,
        redirect: "/login",
        acl: "DEVELOPMENT_OPP_LIST_VIEW",
      },
      {
        name: "Discount Approvals",
        path: "/rentals/trip-approvals",
        Component: RentalTripApprovalListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_VIEW",
      },
      {
        name: "Commission Approvals",
        path: "/rentals/commission-approvals",
        Component: RentalCommissionApprovalListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_VIEW",
      },
      {
        name: "Force Booking Approvals",
        path: "/rentals/force-book-approvals",
        Component: RentalForceBookingApprovalListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_VIEW",
      },
      {
        name: "Payment Approvals",
        path: "/rentals/payment-approvals",
        Component: RentalPaymentApprovalListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_VIEW",
      },
      // {
      //   name: "Deposit Approvals",
      //   path: "/rentals/security-deposit-approvals",
      //   Component: RentalDepositApprovalListing,
      //   secure: true,
      //   redirect: "/login",
      //   acl: "RENTAL_OPP_VIEW",
      // },
      {
        name: "Invoice Approvals",
        path: "/rentals/invoice-approvals",
        Component: RentalInvoiceApprovalListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_VIEW",
      },
      {
        name: "Request Tickets",
        path: "/rentals/request-ticket",
        Component: RequestTicketListing,
        secure: true,
        redirect: "/login",
        acl: "RENTAL_OPP_VIEW",
      },
    ],
  },
  // DEVELOPMENT
  {
    name: "Isprava",
    pages: [
      {
        name: "Opportunities",
        path: "/development/opportunities",
        Component: DevelopmentOpportunityListing,
        secure: true,
        redirect: "/login",
        acl: "DEVELOPMENT_OPP_LIST_VIEW",
      },
      {
        path: "/development/opportunities/:oid",
        Component: DevelopmentOpportunityDetails,
        secure: true,
        redirect: "/login",
        acl: "DEVELOPMENT_OPP_VIEW",
      },
      {
        name: "Delivery Tracker",
        path: "/development/deliveryTracker",
        Component: DeliveryTracker,
        secure: true,
        redirect: "/login",
        acl: "DEVELOPMENT_OPP_LIST_VIEW",
      },
      {
        name: "Site View Tracker",
        path: "/development/siteViewTrackerListing",
        Component: SiteViewTrackerListing,
        secure: true,
        redirect: "/login",
        acl: "DEVELOPMENT_OPP_LIST_VIEW",
      },
    ]
  },
  // Chapter
  {
    name: "The Chapter",
    pages: [
      {
        name: "Opportunities",
        path: "/chapter/opportunities",
        Component: ChapterListing,
        secure: true,
        redirect: "/login",
        acl: "CHAPTER_OPP_LIST_VIEW",
      },
      {
        path: "/chapter/opportunities/:oid",
        Component: ChapterDetails,
        secure: true,
        redirect: "/login",
        acl: "CHAPTER_OPP_VIEW",
      },
      {
        name: "Delivery Tracker",
        path: "/chapter/deliveryTracker",
        Component: ChapterDeliveryTracker,
        secure: true,
        redirect: "/login",
        acl: "DEVELOPMENT_OPP_LIST_VIEW",
      },
      {
        name: "Site View Tracker",
        path: "/chapter/siteViewTrackerListing",
        Component: SiteViewTrackerListing,
        secure: true,
        redirect: "/login",
        acl: "CHAPTER_OPP_LIST_VIEW",
      },
    ]
  },
  // HOME ACQUISITION
  {
    name: "Home Acq",
    path: "/home_acquisition/opportunities",
    Component: HAOpportunityListing,
    secure: true,
    redirect: "/login",
    acl: "HOMEACQUI_OPP_LIST_VIEW",
  },
  {
    path: "/home_acquisition/opportunities/:oid",
    Component: HAOpportunityDetails,
    secure: true,
    redirect: "/login",
    acl: "HOMEACQUI_OPP_VIEW",
  },
  // AGENTS
  {
    name: "Agents",
    path: "/agents",
    Component: AgentsListing,
    secure: true,
    redirect: "/login",
    acl: "AGENT_LIST_VIEW",
  },
  {
    path: "/agents/:agent_id",
    Component: AgentDetails,
    secure: true,
    redirect: "/login",
    acl: "AGENT_VIEW",
  },
  // CONCIERGE
  {
    name: "Concierge",
    path: "/concierge/tickets",
    Component: ConciergeTicketListing,
    secure: true,
    redirect: "/login",
    acl: "TICKET_LIST",
  },
  {
    path: "/concierge/tickets/:concierge_id",
    Component: ConciergeDetails,
    secure: true,
    redirect: "/login",
    acl: "TICKET_VIEW",
  },
  // CUSTOMERS
  // {
  //   name: "Customers",
  //   path: "/customers",
  //   Component: CustomerListing,
  //   secure: true,
  //   redirect: "/login",
  //   acl: "CUSTOMER_LIST_VIEW",
  // },
  {
    path: "/customers/:cid",
    Component: CustomerDetails,
    secure: true,
    redirect: "/login",
    acl: "CUSTOMER_VIEW",
  },

  // ADMIN
  // {
  //   name: "Staff",
  //   path: "/admin/staff",
  //   Component: StaffManagement,
  //   secure: true,
  //   redirect: "/login",
  //   acl: "STAFF_LIST_VIEW",
  // },
  {
    path: "/admin/staff/:id",
    Component: StaffProfile,
    secure: true,
    redirect: "/login",
    acl: "STAFF_VIEW",
  },
  // NOT FOUND
  {
    path: "*",
    Component: NotFound,
  },
];

export default PAGE_TEMPLATES;
