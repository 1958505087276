import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import clx from "classnames";
import React, { useEffect, useState } from "react";

import { useMasters } from "../../contexts/masters-context";
import { useRentalTripService } from "../../contexts/rental-trip-service-context";
import AccessControlGuard, { EMPTY } from "../AccessControlGuard";
import AgentSelect from "../AgentSelect";
import Form, { FormRenderProps, getError } from "../Form";
import HOReferralSelect from "../HOReferralSelect";
import StaffSelect from "../StaffSelect";
import RENTAL_TRIP_ADD_SCHEMA from "./rental-trip-add-schema";
import useRentalTripAddStyles from "./RentalTripAddFormStyles";

interface RentalTripAddProps {
  onUpdate: (slug: string) => any;
  defaultValue?: any;
  customerId?: number;
  callDialer?: boolean;
}

function RentalTripAdd(props: RentalTripAddProps) {
  const { masters } = useMasters(),
    { addTrip, checkPhone, updatePhoneDetails } = useRentalTripService(),
    classNames = useRentalTripAddStyles(),
    {
      defaultValue: {
        role,
        message,
        first_name,
        last_name,
        email,
        phone_no,
        location,
        source_of_enquiry,
        source_of_enquiry_other,
        poc_exec,
        poc_head,
        agent,
        home_owner_id,
        brand_id,
      } = {},
      customerId,
      callDialer,
    } = props,
    title = "Add Trip",
    closeIconClassName = clx("material-icons", classNames.close),
    defaultPhoneNo = phone_no ? phone_no.split(" ", 2) : "",
    defaultValue = {
      role: role || "",
      message: message || "",
      first_name: first_name || "",
      last_name: last_name || "",
      email: email || "",
      country_code: phone_no ? defaultPhoneNo[0] : "+91",
      phone_no: defaultPhoneNo[1] || "",
      location: location || "",
      source_of_enquiry: source_of_enquiry || "",
      source_of_enquiry_other: source_of_enquiry_other || "",
      poc_exec: poc_exec || "",
      poc_head: poc_head || "",
      agent: agent || "",
      home_owner_id: home_owner_id || "",
      customer_id: customerId || "",
      brand_id: brand_id?.toString() || "",
    },
    { sources, locations: location_options } = masters,
    { rental } = sources,
    { onUpdate } = props,
    [checkNumber, updateCheckNumber] = useState<boolean>(customerId ? false : true),
    [editAcess, updateEditAcess] = useState<boolean>(true),
    [defaultTripValues, updatedefaultTripValues] = useState<any>(defaultValue),
    [defaultEdit, updateDefaultEdit] = useState<boolean>(false),
    checkPhoneNo = async (values: any) => {

      const { error, response } = await checkPhone(values.phone_no, values?.country_code, "1");

      if (error || !response) {
        return;
      }

      const updatedDefaultValue = {
        id: response.length > 0 ? response[0].id : null,
        role: role || "",
        message: message || "",
        first_name: response.length > 0 ? response[0].first_name : first_name,
        last_name: response.length > 0 ? response[0].last_name : last_name,
        email: response.length > 0 ? response[0].email?.email : email,
        email_id: response.length > 0 ? response[0].email?.id : null,
        country_code: response.length > 0 ? response[0].phone_no?.country_code : values.country_code,
        phone_no: response.length > 0 ? response[0].phone_no?.mobile : values.phone_no,
        phone_id: response.length > 0 ? response[0].phone_no?.id : null,
        location: location || "",
        source_of_enquiry: source_of_enquiry || "",
        source_of_enquiry_other: source_of_enquiry_other || "",
        poc_exec: poc_exec || "",
        poc_head: poc_head || "",
        agent: agent || "",
        home_owner_id: home_owner_id || "",
        customer_id: response.length > 0 ? response[0].customer_id : customerId,
        brand_id: brand_id?.toString() || "1",
      };

      updatedefaultTripValues(updatedDefaultValue);
      updateCheckNumber(false);

      if (response.length > 0) {
        updateEditAcess(true);
      } else {
        updateEditAcess(false);
      }

    },
    onEdit = async () => {
      updateEditAcess(false);
    },
    onAddTrip = async (values: any) => {
      const {
        message,
        first_name,
        last_name,
        email,
        country_code,
        phone_no,
        location,
        source_of_enquiry,
        source_of_enquiry_other,
        poc_exec,
        poc_head,
        agent,
        home_owner_id,
        id,
        brand_id,
      } = values,
        phoneArray = phone_no.split(" "),
        phoneNo = phoneArray[1] ?? phone_no,
        { response, error } = await addTrip(
          first_name,
          last_name,
          email,
          country_code,
          phoneNo,
          location,
          source_of_enquiry,
          message,
          brand_id,
          source_of_enquiry_other,
          poc_exec,
          poc_head,
          agent,
          home_owner_id,
          id,
        );

      if (error) {
        alert(error.message);
        return;
      }

      if (!response) {
        alert("Unknown error");
        return;
      }

      onUpdate(response);
    },
    onSubmit = async (values: any) => {

      if (defaultEdit && values.customer_id) {

        if (!window.confirm("Updates will reflect across all trip cards, including past data")) {
          return;
        }

        const {
          first_name,
          last_name,
          email,
          phone_no,
          country_code,
        } = values,
          { response, error } = await updatePhoneDetails(
            defaultTripValues.id,
            first_name,
            last_name,
            phone_no,
            defaultTripValues.phone_id,
            country_code,
            email,
            defaultTripValues.email_id,
          );

        if (error) {
          alert(error.message);
          return;
        }

        if (!response) {
          alert("Unknown error");
          return;
        }

        if (response) {
          onAddTrip(values)
        }

      }
      else {
        onAddTrip(values)
      }

    };

  useEffect(() => {
    if (callDialer === true) {
      checkPhoneNo(defaultValue);
    }
  }, [callDialer]);

  return (
    <>
      <CardHeader
        title={
          <div className={classNames.title}>
            {title}
            <span
              className={closeIconClassName}
              onClick={onUpdate?.bind(null, "")}
            >
              close
            </span>
          </div>
        }
      />
      <Divider className={classNames.divider} />
      <CardContent className={classNames.contentWide}>
        <Form
          className={classNames.fields}
          defaultValue={defaultTripValues}
          id={FORM_ID}
          onSubmit={onSubmit}
          validationSchema={RENTAL_TRIP_ADD_SCHEMA}
        >
          {(formProps: FormRenderProps) => {
            const { values, handleChange, handleBlur } = formProps,
              {
                message,
                first_name,
                last_name,
                email,
                country_code,
                phone_no,
                location,
                source_of_enquiry,
                source_of_enquiry_other,
                poc_exec,
                poc_head,
                agent,
                home_owner_id,
              } = values,
              messageError = getError(formProps, "message"),
              first_nameError = getError(formProps, "first_name"),
              last_nameError = getError(formProps, "last_name"),
              emailError = getError(formProps, "email"),
              phone_noError = getError(formProps, "phone_no"),
              locationError = getError(formProps, "location"),
              source_of_enquiryError = getError(formProps, "source_of_enquiry"),
              source_of_enquiry_otherError = getError(
                formProps,
                "source_of_enquiry_other",
              ),
              poc_execError = getError(formProps, "poc_exec"),
              poc_headError = getError(formProps, "poc_head", false),
              agentError = getError(formProps, "agent"),
              HOReferralError = getError(formProps, "home_owner_id"),
              sourceOfEnquiryOtherNode = SOURCE_ENQUIRY_OTHER_REASON.has(
                source_of_enquiry,
              ) ? (
                <TextField
                  error={Boolean(source_of_enquiry_otherError).valueOf()}
                  helperText={source_of_enquiry_otherError}
                  id="source_of_enquiry_other"
                  label="Source of enquiry other *"
                  name="source_of_enquiry_other"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={source_of_enquiry_other || ""}
                  variant="outlined"
                />
              ) : null,
              agentNode =
                source_of_enquiry === "agent" ? (
                  <AgentSelect
                    disabled={role === "agent"}
                    error={Boolean(agentError).valueOf()}
                    helperText={agentError}
                    id="agent"
                    label="Agent *"
                    name="agent"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={agent}
                    variant="outlined"
                  />
                ) : null,
              hoReferralNode =
                source_of_enquiry === "homeowner_ref" ? (
                  <HOReferralSelect
                    error={Boolean(HOReferralError).valueOf()}
                    helperText={HOReferralError}
                    id="home_owner_id"
                    label="Homeowner Referral *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={home_owner_id}
                    variant="outlined"
                  />
                ) : null;

            return (
              <>
                <TextField
                  disabled={editAcess}
                  error={Boolean(first_nameError).valueOf()}
                  helperText={first_nameError}
                  id="first_name"
                  label="First Name *"
                  name="first_name"
                  onBlur={handleBlur}
                  onChange={e => { handleChange(e); updateDefaultEdit(true) }}
                  value={first_name}
                  variant="outlined"
                />
                <TextField
                  disabled={editAcess}
                  error={Boolean(last_nameError).valueOf()}
                  helperText={last_nameError}
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  onBlur={handleBlur}
                  onChange={e => { handleChange(e); updateDefaultEdit(true) }}
                  value={last_name}
                  variant="outlined"
                />
                <TextField
                  disabled={editAcess}
                  error={Boolean(emailError).valueOf()}
                  helperText={emailError}
                  id="email"
                  label="Email *"
                  name="email"
                  onBlur={handleBlur}
                  onChange={e => { handleChange(e); updateDefaultEdit(true) }}
                  value={email}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    startAdornment: (
                      <input
                        className={classNames.phoneCode}
                        disabled={role === "agent"}
                        id="country_code"
                        maxLength={5}
                        name="country_code"
                        onChange={handleChange}
                        placeholder="+XX"
                        type="text"
                        value={country_code}
                      />
                    ),
                    endAdornment: (
                      phone_no.length > 0 ?
                        (
                          (customerId || callDialer) ?
                            null
                            :
                            <Button
                              color="secondary"
                              disabled={!checkNumber || Boolean(phone_noError).valueOf()}
                              onClick={checkPhoneNo.bind(null, { phone_no, country_code })}
                              variant="contained"
                            >
                              Check
                            </Button >
                        )
                        : null
                    ),
                    readOnly: (role === "agent" ? true : false),
                  }}
                  disabled={!checkNumber}
                  error={Boolean(phone_noError).valueOf()}
                  helperText={phone_noError}
                  id="phone_no"
                  label="Phone no *"
                  name="phone_no"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={phone_no}
                  variant="outlined"
                />
                <TextField
                  SelectProps={{ native: true }}
                  disabled={checkNumber}
                  error={Boolean(locationError).valueOf()}
                  helperText={locationError}
                  id="location"
                  label="Location *"
                  name="location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select={true}
                  value={location}
                  variant="outlined"
                >
                  <option value=""></option>
                  {location_options.map(each => {
                    const { name, value } = each;
                    return (
                      <option
                        key={value}
                        value={value}
                      >
                        {name}
                      </option>
                    );
                  })}
                </TextField>
                {role === "agent" ?
                  null
                  :
                  <TextField
                    SelectProps={{ native: true }}
                    disabled={role === "agent" || checkNumber}
                    error={Boolean(source_of_enquiryError).valueOf()}
                    helperText={source_of_enquiryError}
                    id="source_of_enquiry"
                    label="Source *"
                    name="source_of_enquiry"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select={true}
                    value={source_of_enquiry}
                    variant="outlined"
                  >
                    <option value=""></option>
                    {rental.map(sourceOption => {
                      const { name, value } = sourceOption;

                      return (
                        <option
                          key={value}
                          value={value}
                        >
                          {name}
                        </option>
                      );
                    })}
                  </TextField>
                }
                {sourceOfEnquiryOtherNode}
                {role === "agent" ? null : agentNode}
                {hoReferralNode}
                <TextField
                  className={classNames.message}
                  disabled={checkNumber}
                  error={Boolean(messageError).valueOf()}
                  helperText={messageError}
                  id="message"
                  label="Message"
                  multiline={true}
                  name="message"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  rows="4"
                  value={message}
                  variant="outlined"
                />
                {role !== "agent" &&
                  <>
                    <StaffSelect
                      disabled={checkNumber}
                      error={Boolean(poc_execError).valueOf()}
                      helperText={poc_execError}
                      id="poc_exec"
                      label="POC Executive *"
                      name="poc_exec"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={poc_exec}
                      variant="outlined"
                    />
                    <StaffSelect
                      disabled={checkNumber}
                      error={Boolean(poc_headError).valueOf()}
                      helperText={poc_headError}
                      id="poc_head"
                      label="POC Head"
                      name="poc_head"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={poc_head}
                      variant="outlined"
                    />

                    {
                      //FM-484

                      /* <TextField
                        SelectProps={{ native: true }}
                        disabled={editAcess}
                        error={Boolean(brand_idError).valueOf()}
                        helperText={brand_idError}
                        id="brand_id"
                        label="Brand *"
                        name="brand_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select={true}
                        value={brand_id}
                        variant="outlined"
                      >
                        <option value=""></option>
                        {brands.map(each => {
                          const { name, value } = each;
                          return (
                            <option
                              key={value}
                              value={value}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </TextField> */
                    }
                  </>
                }
                <div>&nbsp;</div>
              </>
            );
          }}
        </Form>
      </CardContent>
      <CardActions>
        {!checkNumber ?
          ((customerId || !editAcess) ? null :
            <AccessControlGuard
              ac="UPDATE_CONTACT_PRIMARY_DETAILS"
              fallback={EMPTY}
            >
              <Button
                className={classNames.save}
                color="secondary"
                onClick={onEdit}
                variant="contained"
              >
                Edit
              </Button>
            </AccessControlGuard>
          )
          : null
        }
        <Button
          className={classNames.save}
          color="secondary"
          disabled={checkNumber}
          form={FORM_ID}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </CardActions>
    </>
  );
}

export default RentalTripAdd;

const SOURCE_ENQUIRY_OTHER_REASON = new Set([
  "reference",
  "word_of_mouth",
  "other",
  "isprava",
  "chapter_reference",
  "lohono",
]);

const FORM_ID = "rental-trip-add";
