import {
  HomeAcquisitionOpportunity,
  HomeAcquisitionOpportunityItem,
} from "../types/home-acquisition";
import { Pagination } from "../types/pagination";
import { IHomeAcquisitionService } from "../types/services/home-acquisition-service";
import { FetchResult, GET, PATCH, POST } from "../utils/fetch";

type OpportunityAddResponse = {
  slug: string;
};

type OpportunityListResponse = {
  opportunities: any[];
  paginate: Pagination;
};

type OpportunityResponse = {
  opportunity: any;
};

function getFilterQp(
  stages?: string[],
  opportunity_status?: string[],
  sources?: string[],
  enquired_from?: string,
  enquired_until?: string,
  staff?: string,
  location?: string,
  search?: string,
  page?: string,
  page_size?: string,
) {
  const qp = new URLSearchParams();
  if (stages) {
    stages.forEach((stage) => {
      qp.append("stages[]", stage);
    });
  }

  if (opportunity_status) {
    opportunity_status.forEach((status) => {
      qp.append("opportunity_status[]", status);
    });
  }

  if (sources) {
    sources.forEach((source) => {
      qp.append("sources[]", source);
    });
  }
  if (search) {
    qp.set("query", search);
  }
  if (enquired_from) {
    qp.set("start_date", enquired_from);
  }
  if (enquired_until) {
    qp.set("end_date", enquired_until);
  }
  if (staff) {
    qp.set("poc_exec_id", staff);
  }
  if (location) {
    qp.set("location", location);
  }
  if (page) {
    qp.set("page", page);
  }
  if (page_size) {
    qp.set("per_page", page_size);
  }

  return qp;
}

class HomeAcquisitionService implements IHomeAcquisitionService {
  async addOpportunity(
    first_name: string,
    last_name: string,
    email: string,
    phone_no: string,
    location: string,
    source_of_enquiry: string,
    poc_exec: number,
    message: string,
    source_of_enquiry_region: string,
    source_of_enquiry_city: string,
    source_of_enquiry_other?: string,
    poc_head?: number,
    agent?: number,
    customer_id?: number,
  ): Promise<FetchResult<string>> {
    const [code, phoneNo] = phone_no.split(" ");

    const payload: any = {
      email: email || "",
      country_code: code || "",
      mobile: phoneNo || "",
      location: location,
      source: source_of_enquiry,
      source_others: source_of_enquiry_other || "",
      poc_exec_id: poc_exec,
      message: message || "",
      source_city: source_of_enquiry_city || "",
      source_region: source_of_enquiry_region || "",
    };

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    payload.name = name;

    if (poc_head) {
      payload.poc_head_id = poc_head;
    }

    if (agent !== undefined) {
      payload.agent_id = agent;
    }

    if (customer_id) {
      payload.contact_id = customer_id;
    }

    const url = `/api/v2/home_acqui/opportunities`,
      { response, error } = await POST<OpportunityAddResponse>(url, payload);

    void url;

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    return {
      error: null,
      response: response.slug,
    };
  }

  async getOpportunities(
    stages?: string[],
    opportunity_status?: string[],
    sources?: string[],
    enquired_from?: string,
    enquired_until?: string,
    staff?: string,
    location?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<HomeAcquisitionOpportunityItem[]>> {
    const qp = getFilterQp(
      stages,
      opportunity_status,
      sources,
      enquired_from,
      enquired_until,
      staff,
      location,
      search,
      page,
      page_size,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/home_acqui/opportunities";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<OpportunityListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.opportunities.map((each) => {
      const {
          email,
          location,
          mobile,
          name,
          next_follow_up,
          poc_exec,
          slug,
          source,
          stage,
          updated_at,
          verified,
          country_code,
          status,
        } = each,
        phoneNo = `${country_code || ""} ${mobile || ""}`.trim(),
        opportunity: HomeAcquisitionOpportunityItem = {
          name: name || "",
          email: email || "",
          phone_no: phoneNo,
          stage: stage || "",
          location: location || "",
          updated_date: updated_at || "",
          follow_up_date: next_follow_up || "",
          source: source || "",
          poc_executive: poc_exec || "",
          slug: slug || "",
          verified: verified || false,
          status: status || "no_rating",
        };

      return opportunity;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  getOpportunitiesCSV(
    stages?: string[],
    opportunity_status?:string[],
    sources?: string[],
    enquired_from?: string,
    enquired_until?: string,
    staff?: string,
    location?: string,
  ): string {
    const qp = getFilterQp(
      stages,
      sources,
      opportunity_status,
      enquired_from,
      enquired_until,
      staff,
      location,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/development/opportunities.csv";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    return url;
  }

  async getOpportunity(
    opportunitySlug: string,
  ): Promise<FetchResult<HomeAcquisitionOpportunity>> {
    const url = `/api/v2/home_acqui/opportunities/${opportunitySlug}`,
      { error, response } = await GET<OpportunityResponse>(url);

    void url;

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        name,
        // email,
        // mobile,
        status,
        slug,
        // updated_at,
        // next_follow_up,
        source,
        enquired_at,
        location,
        area,
        source_country,
        source_city,
        adults,
        extra_adults,
        bedrooms,
        bathrooms,
        pools,
        gdrive_urls,
        source_others,
        // message,
        poc_head_id,
        poc_exec_id,
        agent_id,
        lat,
        long,
        address,
        gmaps_url,
        contact_id,
        stages,
        isprava_built,
        message,
        current_stage = "",
        ho_contact,
        ho_name,

        property_name = "Awesome property",
        utm_source = "google",
        utm_medium = "google",
        utm_campaign = "google",
        utm_content = "google",
      } = response.opportunity as any,
      opportunity: HomeAcquisitionOpportunity = {
        name: name || "",
        opportunity_slug: slug || "",
        property_name: property_name || "",
        ho_contact: ho_contact || "",
        ho_name: ho_name || "",
        location: location || "",
        property_area: area || 0,
        source_country: source_country || "",
        source_city: source_city || "",
        bedroom_count: bedrooms || 0,
        bath_count: bathrooms || 0,
        pool_count: pools || 0,
        adult_count: adults || 0,
        extra_adult_count: extra_adults || 0,
        property_folder_links: gdrive_urls || [],
        customer_id: contact_id || 0,
        address: address || "",
        google_url: gmaps_url || "",
        lat: lat || 0,
        long: long || 0,
        current_stage: current_stage || "",
        stages: stages || [],
        enquiry_date: enquired_at || "",
        source_of_enquiry: source || "",
        source_of_enquiry_other: source_others || "",
        utm_source: utm_source || "",
        utm_medium: utm_medium || "",
        utm_campaign: utm_campaign || "",
        utm_content: utm_content || "",
        poc_executive: poc_exec_id || 0,
        poc_head: poc_head_id || 0,
        agent: agent_id || 0,
        status: status || "",
        isprava_built: isprava_built || false,
        message: message || "",
      };

    return {
      error: null,
      response: opportunity,
    };
  }

  async updateOpportunity(
    opportunity_slug: string,
    property_name: string,
    ho_contact:string,
    ho_name:string,
    location: number,
    property_area: number,
    source_country: string,
    source_city: string,
    bedroom_count: number,
    bath_count: number,
    pool_count: number,
    adult_count: number,
    extra_adult_count: number,
    property_folder_links: string[],
    address: string,
    google_url: string,
    lat: number,
    long: number,
    agent: number,
    isprava_built: boolean,
    message: string,
    source_of_enquiry: string,
    source_of_enquiry_other?: string,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "property_name",
          value: property_name,
        },
        {
          key: "ho_name",
          value: ho_name,
        },
        {
          key: "ho_contact",
          value: ho_contact,
        },
        {
          key: "location",
          value: location,
        },
        {
          key: "area",
          value: property_area,
        },
        {
          key: "source_country",
          value: source_country,
        },
        {
          key: "source_city",
          value: source_city,
        },
        {
          key: "bedrooms",
          value: bedroom_count,
        },
        {
          key: "bathrooms",
          value: bath_count,
        },
        {
          key: "pools",
          value: pool_count,
        },
        {
          key: "adults",
          value: adult_count,
        },
        {
          key: "extra_adults",
          value: extra_adult_count,
        },
        {
          key: "gdrive_urls",
          value: property_folder_links,
        },
        {
          key: "address",
          value: address,
        },
        {
          key: "gmaps_url",
          value: google_url,
        },
        {
          key: "lat",
          value: lat,
        },
        {
          key: "long",
          value: long,
        },
        {
          key: "agent_id",
          value: agent,
        },
        {
          key: "isprava_built",
          value: isprava_built,
        },
        {
          key: "source",
          value: source_of_enquiry,
        },
        {
          key: "source_others",
          value: source_of_enquiry_other,
        },
        {
          key: "message",
          value: message,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number | string[] | boolean }),
      url = `/api/v2/home_acqui/opportunities/${opportunity_slug}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updatePOC(
    opportunity_slug: string,
    poc_executive?: string,
    poc_head?: string,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "poc_exec_id",
          value: poc_executive,
        },
        {
          key: "poc_head_id",
          value: poc_head,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number }),
      url = `/api/v2/home_acqui/opportunities/${opportunity_slug}/update_poc`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateStages(
    opportunity_slug: string,
    checked: number[],
  ): Promise<FetchResult<void>> {
    const payload = {
        checked: checked,
      },
      url = `/api/v2/home_acqui/opportunities/${opportunity_slug}/stage`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default HomeAcquisitionService;
