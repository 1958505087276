import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

import {
  ContextMenu,
  ContextMenuProviderState,
  ContextMenuState,
} from "./context-menu";

const DEFAULT_CONTEXT_MENU_STATE: ContextMenuState = {
  element: null,
  menu: [],
  showContextMenu: async () => "",
};

const ContextMenuContext = createContext<ContextMenuState>(
  DEFAULT_CONTEXT_MENU_STATE,
);

interface ContextMenuProviderProps {
  children: ReactNode;
}

function ContextMenuProvider(props: ContextMenuProviderProps) {
  const [state, updateState] = useState<ContextMenuProviderState>(DEFAULT_CONTEXT_MENU_STATE),
    { children } = props,
    { element, menu } = state,
    onDismissContextMenu = (selection: string = "") => {
      const { resolve } = state;

      updateState(DEFAULT_CONTEXT_MENU_STATE);

      if (resolve) {
        resolve(selection);
      }
    },
    onShowContextMenu = async (element: Element, menu: ContextMenu[]) => {
      return new Promise<string>(resolve => {
        updateState({
          element: element,
          menu: menu,
          resolve: resolve,
        });
      });
    },
    value: ContextMenuState = useMemo(
      () => ({
        ...state,
        showContextMenu: onShowContextMenu,
      }),
      [state],
    );

  return (
    <ContextMenuContext.Provider value={value}>
      <>
        {children}
        <Menu
          anchorEl={element}
          id="context-menu"
          onClose={onDismissContextMenu.bind(null, "")}
          open={Boolean(element && menu.length)}
        >
          {menu.map(item => {
            const { label, value, color } = item;

            return (
              <MenuItem
                key={value}
                onClick={onDismissContextMenu.bind(null, value)}
                style={color ? { color: color } : undefined}
              >
                {label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    </ContextMenuContext.Provider>
  );
}

function useContextMenu() {
  const { showContextMenu } = useContext(ContextMenuContext);

  return { showContextMenu };
}

export { ContextMenuProvider, useContextMenu };
